(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

document.addEventListener('DOMContentLoaded', function (e) {
	var container = document.getElementById('wpsn-filepage-related-stage');

	if (!container) return;

	var topList = container.querySelector('.top-banner-list');
	var bottomList = container.querySelector('.wheelie-list');

	if (!topList || !bottomList) return;

	var topListChildren = topList.querySelectorAll('li.list-item');
	var bottomListChildren = bottomList.querySelectorAll('li.list-item');
	var numElements = topListChildren.length;

	if (numElements <= 0 || bottomListChildren.length != numElements) return;

	var offsetX = topListChildren.item(0).clientWidth + 50; // assumes marginRight is 50
	var currentElementIndex = 0;

	if (numElements > 1) setInterval(moveList, 3500);

	function moveList() {
		// If this is the last element, set no-transition and set transformation-x to 0.
		// A timer function called in 5ms will then properly move.
		if (currentElementIndex === numElements - 1) {
			currentElementIndex = 0;

			topList.classList.add('no-transition');
			bottomList.classList.add('no-transition');
			var style_transform1 = '-webkit-transform:translate3D(0, 0, 0);transform:translate3D(0, 0, 0);';
			topList.setAttribute('style', style_transform1);
			bottomList.setAttribute('style', style_transform1);

			setTimeout(moveList, 20);
		} else {
			currentElementIndex++;

			topList.classList.remove('no-transition');
			bottomList.classList.remove('no-transition');

			var translateX = offsetX * currentElementIndex;
			var style_transform2 = '-webkit-transform:translate3D(-' + translateX + 'px, 0, 0);transform:translate3D(-' + translateX + 'px, 0, 0);';
			topList.setAttribute('style', style_transform2);
			bottomList.setAttribute('style', style_transform2);
		}
	}
});

},{}]},{},[1]);
